.toolbar {
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    height: var(--mp-toolbar-height);
    left: 0px;
    top: var(--mp-topbar-height);
    background: #ffffff;
    border-bottom: 1px solid #dbdbdb;
  }
  .toolbar-container {
    display: flex;
    height: 100%;
    align-items: center;
    width: 97%;
    margin: 0 auto;
  }
  .bar-menu-icon {
    display: none;
  }
  @media (max-width: 1100px) {
    .bar-menu-icon {
      display: block;
    }
  }