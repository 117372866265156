@import '../../styles';

.logout-modal {
  max-width: 30em;
  margin: 10em 0 0 35em;

  .modal-content {
    height: auto;
  }

  .modal-header {
    z-index: 800;

    .modal-title {
      font-family: 'Maersk Text', sans-serif !important;
      font-size: 1em !important;
      font-weight: bold !important;
    }
  }

  .modal-header .btn-close {
    background: none;
    border: none;
    font-size: 1.5rem;
    line-height: 1;
    cursor: pointer;
  }

  .modal-body {
    padding-top: 1.5em !important;
    font-family: 'Maersk Text', sans-serif !important;
    font-size: 1em !important;
  }

  .modal-footer {
    background-color: $color-grey-100 !important;
  }

}