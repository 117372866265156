@import '~@maersk-global/mds-design-tokens/maersk/light/web/css/design-tokens.css';
@import '~@maersk-global/fonts/maeu/fonts.css';
@import '../src/styles/toolbar.scss';
@import '../src/styles/topnav.scss';
@import '../src/layouts/SideNav/SideNav.module.scss';
@import '~bootstrap/dist/css/bootstrap.min.css';

:root {
  --mp-topbar-height: 80px;
  --mp-toolbar-height: 64px;
  --mp-treeview-width: 300px;
  --mp-font-size: 16px;
  --mp-top-nav-bottom-gap: 22px;
  --mp-utility-container-bottom-gap: 18px;
  --mp-top-nav-menu-margin-top: 26px;
  --mp-logo-image-height: 32px;
  --mp-font-family: 'Maersk Headline', -apple-system, BlinkMacSystemFont,
    'Microsoft JhengHei', 'Microsoft Yahei', '微软雅黑', STXihei, '华文细黑',
    sans-serif;

  --mp-text-color: #141414;
  --mp-text-color--hover: #0073ab;
  --mp-text-color--disabled: #cfcfcf;
  --mp-text-inverted-color: #ffffff;
  --mp-icon-default-color: #141414;
  --mp-icon-subdued-color: #878787;
  --mp-icon-disabled-color: #cfcfcf;
  --mp-view-bar-current-color: #42b0d5;
  --mp-tree-item-current-color: #00243d;
  --mp-submenu-color--hover: #42b0d533;
  --mp-background-info-color: #b5e0f5;
  --mp-background-success-color: #c5e5c2;
  --mp-bakground-warning-color: #ffeba8;
  --mp-background-danger-color: #e9b2b7;
  --mp-border-info-color: #42b0d5;
  --mp-border-success-color: #40ab35;
  --mp-border-warning-color: #ffd029;
  --mp-border-danger-color: #b80012;
  --mp-table-grid-line-color: #dbdbdb;
  --mp-table-header-background-color: #f7f7f7;
  --mp-table-cell-background-color: #ffffff;
}
@media screen and (max-width: 1100px) {
  :root {
    --mp-topbar-height: 64px;
    --mp-toolbar-height: 48px;
    --mp-font-size: 14px;
    --mp-top-nav-bottom-gap: 16px;
    --mp-utility-container-bottom-gap: 12px;
    --mp-top-nav-menu-margin-top: 20px;
    --mp-logo-image-height: 28px;
  }
}
body {
  margin: 0;
  position: relative;
  min-height: 100%;
  overflow-x: hidden;
  font-size: var(--mp-font-size);
  font-family: var(--mp-font-family);
  background: rgba(0, 0, 0, 0.03);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* padding: 25px; */
}
.pagination-wrapper{
  margin-top: 0.5rem;
  margin-bottom: 1.1rem;
}

.sidenav-container {
  position: fixed;
}


.header {
  z-index: 15; 
  height: 80px;
}

.main {
  margin-left: 75px;
}

.menu {
  z-index: 16;
}

.footer {
  background-color: white;
  border-top: 10px solid #dbdbdb;
  position: fixed;
  left: 0;
  bottom: 0;
  
  height: 30px;
  width: 100%;
  overflow: hidden;
  text-align: center;
  top: auto;
 
  text-size-adjust: auto;
  font-size: 14px;
  padding-top: 3px;
  padding-bottom: 25px;
 
 }
