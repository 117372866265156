.sidenav-container {
    top: 0;
}

.sidenav {
    width: 250px;
    transition: width 0.2s ease-in-out;
    height: 100vh;
    background-color: #00243D;
}

.sidenavClosed {
    transition: width 0.3s ease-in-out;
    width: 75px;
    background-color: #00243D;
    height: 100vh;
}

.sideitem {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 20px;
    cursor: pointer;
    color: #fff;
    text-decoration: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.sideitem:hover {
    background-color: #193A50;
    cursor: pointer;
}

.sideitem:hover .linkText {
    color: #42B0D5;
}

.sideitem:hover .icon-hover {
    display: block;
}

.sideitem:hover .icon-prime {
    display: none;
}

.linkText {
    color: white;
    font-size: 10px;
    text-align: center;
}

.subnav-linktext {
    color: white;
    font-size: 14px;
    justify-content: center;
}

.menuBtn {
    align-self: center;
    align-self: flex-start;
    justify-self: flex-end;
    color: #B2BAC2;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding-left: 10px;
}

.subnav-item {
    height: 50px;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #f5f5f5;
    font-size: 14px;
    justify-content: center;
}

.subnav-item:hover {
    background-color: #193A50;
    cursor: pointer;
}

.subnav-item:hover .subnav-linktext {
    color: #42B0D5;
}

.hover-menu {
    display: none;
    background-color: #00243D;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    position: absolute;
    left: 75px;
    color: white;
    width: 185px;
}

.close-panel-hover:hover .hover-menu {
    display: block;
}

.parent-title {
    padding: 5px;
    text-align: center;
    border-bottom: 1px solid white;
    font-size: 17px;
}

.subnav-item-list {
    list-style-type: none;
    padding-left: 0rem;
}

.icon-hover {
    display: none;
}

.logo-home-sidenav {
    display: flex;
    margin: 0 0 10px 0;
    padding-top: 25px;
    justify-content: center;
}

.current-nav-item {
    border-left: 1px solid #42B0D5;
}

.current-nav-linktext {
    font-size: 10px;
    color: #42B0D5;
    text-align: center;
}

.current-nav-icon-hide {
    display: none;
}

.current-nav-icon-show {
    display: block;
}

.remove-side-nav {
    display: none;
}

.as-sidetab {
    top: 58% !important;
    max-width: 45em !important;
    min-width: 35em !important;
    height: 45em !important;
}

.as-sidetab-button {
    left: -30px !important;
    height: 30px !important;
    max-width: 400px !important;
    top: 80% !important;
}

.as-sidetab-button-text {
    font-size: 14px !important;
    font-family: "Maersk Text" san-serif !important;
}

.as-sidetab-button-icon {
    width: 20px !important;
    height: 20px !important;
}