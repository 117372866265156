$s-0: 0;
$s-1: 4px;
$s-2: 8px;
$s-3: 12px;
$s-4: 16px;
$s-5: 20px;
$s-6: 24px;
$s-7: 28px;
$s-8: 32px;
$s-9: 36px;
$s-10: 40px;
$s-11: 44px;
$s-12: 48px;
$s-13: 52px;
$s-14: 56px;
$s-15: 60px;
$s-16: 64px;

$sidebar-width: 260px;
$collapsed-sidebar-width: 0px;
$topbar-height: 48px;
