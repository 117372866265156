@import '../../styles';

.unsaved-changes-modal {
    max-width: 100% !important;

    .modal-content {
        height: auto;
        overflow-y: scroll;
        max-height: 90vh;
    }

    .modal-header {
        z-index: 800;

        .modal-title {
            font-family: 'Maersk Text', sans-serif !important;
            font-size: 1em !important;
            font-weight: bold !important;
        }
    }

    .modal-body {
        padding-top: 1.5em !important;
    }

    .warning-text {
        color: $color-red-500 !important;
        font-family: 'Maersk Text', sans-serif !important;
        font-size: 1em !important;
    }

    .section-header {
        font-size: 0.9em !important;
        font-weight: bold !important;
        margin-top: 1.5em !important;
    }

    .table-bordered th {
        font-size: 0.9em !important;
        font-weight: bold !important;
        color: $color-blue-700 !important;
        position: sticky !important;
    }

    .table-bordered td {
        font-size: 0.9em !important;
    }

    .modal-footer {
        background-color: $color-grey-100 !important;
    }
}

.scrollable-table-sections,
.scrollable-table-questions {
    max-height: 14.6em;
    overflow-y: scroll;
}