$color-grey-900: #141414;
$color-grey-800: #363636;
$color-grey-700: #545454;
$color-grey-600: #878787;
$color-grey-500: #b3b3b3;
$color-grey-400: #cfcfcf;
$color-grey-300: #dbdbdb;
$color-grey-200: #ededed;
$color-grey-100: #f7f7f7;
$color-grey-50: #c9ced4;

$color-blue-900: #00243d;
$color-blue-800: #003d6d;
$color-blue-700: #005285;
$color-blue-600: #0074ad;
$color-blue-500: #0590c7;
$color-blue-400: #0ca2cf;
$color-blue-300: #43b1d6;
$color-blue-200: #76c5e0;
$color-blue-100: #91d8f2;
$color-blue-50: #b7e1f5;

$color-red-900: #6b0e05;
$color-red-800: #8a0705;
$color-red-700: #a6120c;
$color-red-600: #c7280f;
$color-red-500: #e02810;
$color-red-400: #fa3b1e;
$color-red-300: #fa6d57;
$color-red-200: #ff8766;
$color-red-100: #ff9d85;
$color-red-50: #ffbba8;

$color-teal-900: #004d40;
$color-teal-800: #006658;
$color-teal-700: #007a6c;
$color-teal-600: #008a7a;
$color-teal-500: #009485;
$color-teal-400: #26a699;
$color-teal-300: #4fb5ab;
$color-teal-200: #4dc7bd;
$color-teal-100: #80d6d0;
$color-teal-50: #a9eae0;

$color-citrus-900: #7c9e00;
$color-citrus-800: #92b300;
$color-citrus-700: #a0bd00;
$color-citrus-600: #afca02;
$color-citrus-500: #c8d90d;
$color-citrus-400: #d5e000;
$color-citrus-300: #dae600;
$color-citrus-200: #e4f000;
$color-citrus-100: #e6f42a;
$color-citrus-50: #f2ff66;

$color-orange-900: #943600;
$color-orange-800: #ac4602;
$color-orange-700: #d15e05;
$color-orange-600: #f27507;
$color-orange-500: #ff9100;
$color-orange-400: #ffa200;
$color-orange-300: #ffb300;
$color-orange-200: #ffc21a;
$color-orange-100: #ffd24d;
$color-orange-50: #ffdf75;

$color-danger: #b80012;
$color-danger-light: #eab3b8;
$color-critical: #f36a5a;

$color-warning: #ffd129;
$color-warning-light: #ffeba8;

$color-success: #41ac35;
$color-success-light: #c5e5c2;

$color-info: #43b1d6;
$color-info-light: #b7e1f5;

$color-white: #ffffff;