.topbar {
    position: fixed;
    height: var(--mp-topbar-height);
    left: 0%;
    right: 0%;
    top: 0px;
    background: #ffffff;
    z-index: 4;
  }
  .top-bar-container {
    display: flex;
    height: 100%;
    align-items: flex-end;
    width: 100%;
    padding: 0 24px;
    margin: 0 auto;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.04),
      0px 3px 14px 2px rgba(0, 0, 0, 0.03), 0px 8px 10px 1px rgba(0, 0, 0, 0.03);
  }
  .nav-container {
    display: flex;
    font-weight: 400;
    line-height: 24px;
    font-size: var(--mp-font-size);
  }
  .nav-header-primary {
    box-sizing: border-box;
    text-decoration: none;
    margin: 0 16px;
    padding-bottom: var(--mp-top-nav-bottom-gap);
    display: block;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transition: color 0.2s;
    transition: color 0.2s;
    color: #141414;
    border-bottom: 4px solid transparent;
  }
  .nav-header-primary-wrapper {
    position: relative;
  
    &:hover > a {
      color: #0073ab;
    }
  }
  .nav-link-dropdown {
    padding-left: 8px;
    padding-top: 2px;
  }
  .nav-header-primary-link-dropdown {
    display: flex;
    text-decoration: none;
    &:hover {
      color: #0073ab;
    }
 }
  .nav-header-primary-link {
    text-decoration: none;
    &:hover {
      color: #0073ab;
    }
  }
  a:visited {
    color: #141414;
    text-decoration: none;
  }
  .nav-header-menu {
    background: #fff;
    color: #141414;
    border-radius: 2px;
    -webkit-box-shadow: 0 0 50px rgb(20 20 20 / 10%);
    box-shadow: 0 0 50px rgb(20 20 20 / 10%);
    position: absolute;
    width: max-content;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    text-align: left;
    margin-top: var(--mp-top-nav-menu-margin-top);
  
    &.menu-open {
      opacity: 1;
      visibility: visible;
    }
  }
  .nav-header-menu-link {
    color: #141414;
    text-decoration: none;
    display: block;
    position: relative;
    cursor: pointer;
    border: 0;
    background-color: transparent;
    padding: 12px 15px;
  
    &:hover {
      background-color: #42b0d533;
    }
  }
  .utility-container {
    display: flex;
    gap: 2em;
    margin-left: auto;
    margin-right: 8px;
    padding-bottom: var(--mp-utility-container-bottom-gap);
  }
  .logo-text {
    margin: auto;
    padding-left: 38px;
    font-size: var(--mp-font-size);
    font-weight: 700;
    line-height: 24px;
    text-transform: uppercase;
  }
  .logo-home {
    display: flex;
    text-decoration: none;
    margin-right: 24px;
    margin-bottom: var(--mp-top-nav-bottom-gap);
    & > img {
      height: var(--mp-logo-image-height);
    }
  }
  .vessel-icon {
    padding-top: 2px;
    padding-right: 4px;
  }
  .current-nav {
    border-bottom: 4px solid #42b0d5;
  }
  .reports {
    color: #141414;
  }
  @media screen and (max-width: 1100px) {
    .hide-small-screen {
      display: none;
    }
  }