@import '../../styles';

.currentusername {
    position: relative;
    display: inline-block;
    cursor: pointer;
    margin: 4px 8px;
    font-family: 'Maersk Text', sans-serif !important;
    font-size: 1em !important;
}

.usernamesection {
    position: absolute;
    right: 40px;
}

.user-info{
    display: inline;
}

.dropdown-content {
    margin-top: 30px;
    margin-bottom: 20px;
    margin-right: 20px;
    display: block;
    position: absolute;
    background-color: #ffffff;
    min-width: 130px;
    box-shadow: 0px 8px 16px 0px #d9d9d9;
    padding: 10px 16px;
    z-index: 1;
    margin-left: 65px;
}

.signout {
    cursor: pointer;
}

.it-help-link-container{
    margin-top: 0.2em;
}

.it-help-link {
    color: #006699 !important;
    text-decoration: none !important;
    font-family: 'Maersk Text', sans-serif !important;
    font-size: 1em !important;
}

.it-help-link:hover {
    color: #006699 !important;
    text-decoration: underline !important;
}

.logout-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
}

.logout-icon {
    width: 20px;
    height: 20px;
    margin-bottom: 0.2em;
}

.it-help-icon{
    width: 18px;
    height: 18px;
    margin-bottom: 0.18em;
    margin-right: 0.5em;
}